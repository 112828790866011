/* Dark-mode */
body.theme-dark {
  background: admefy-color('dark') !important;
  color: admefy-color('light') !important;
  border-color: admefy-color('light') !important;

  *:fullscreen {
    background-color: admefy-color('dark');
  }

  .notification {
    &:hover {
      background-color: admefy-color('dark-gray');
      color: admefy-color('light');
    }
  }
  .navbar-toggler {
    background-image: url(/assets/header/profile.svg);
    background-repeat: no-repeat;
  }

  .channel-box {
    border-color: admefy-color('dark-gray')!important;
  }

  .reply-comment-wrapper,
  .chat-pane__chat-list--comment .body {
    background-color: admefy-color('dark-gray');
    color: admefy-color('light');
  }

  header {
    border-top: 1.5px solid admefy-color('dark-gray');

    @media (max-width: 768px) {
      border: none;
    }

    .explore-button {
      background-image: url(/assets/header/background-toogle-menu.svg);
    }

    .new-logo {
      //background: transparent url('/assets/header/logo-white-green.svg') 0% 0% no-repeat padding-box;
      background: transparent url('/assets/header/logo-admefy.svg') 0% 0% no-repeat padding-box;
    }
  }

  .datatable-pager {

    a,
    li.disabled a {
      color: admefy-color('light');
    }
  }

  ul,
  li,
  h3,
  h4,
  label,
  em,
  i,
  a,
  span,
  mat-label,
  mat-icon,
  button {
    color: admefy-color('light');
  }

  ngx-datatable.ngx-datatable.material.admefy-theme,
  .datatable-header-cell,
  .selected-displays-container,
  .selected-displays-submenu,
  .selected-displays-container-mobile,
  thead,
  tr,
  table,
  th,
  ng-dropdown-panel,
  ng-select div,
  .mat-mdc-paginator,
  .ng-select div,
  .mat-label,
  .mat-mdc-menu-panel,
  .mat-mdc-select-panel,
  .mat-date-range-input-inner,
  .mat-mdc-autocomplete-panel,
  .notification-submenu,
  .admeet-options,
  .admeet-settings,
  .help-menu,
  .bg-filters,
  .parent-popup,
  .popup-elements button,
  .popup-elements select,
  .grdp,
  .card,
  .card-media,
  .btn-previous,
  .event-date,
  .newsletter,
  .admefy-band,
  admefy-dialog-short-url-player,
  admefy-displays-using-media,
  admefy-dialog-my-medias,
  .dropdown-content-menu,
  .landing-main .item,
  .displays-selected-box,
  admefy-media-feed-card .context-submenu,
  .popup-wrapper-admefy,
  admefy-header header nav,
  .dialog-steps,
  .plyr__progress .plyr__tooltip,
  .dropdown-content-lang__decoration,
  .chat-pane__chat-box--user,
  admefy-smart-photo-card .popup,
  ngb-modal-window .modal-content,
  .mapboxgl-ctrl-geocoder--input,
  .cdk-drag.media {
    background: admefy-color('dark') !important;
    color: admefy-color('light') !important;
  }

  .mapboxgl-ctrl-geocoder--button,
  .mapboxgl-ctrl-geocoder {
   background: transparent;
  }

  .mapboxgl-ctrl-geocoder--input {
    border-radius: 16px;
  }

  .mat-calendar-table,
  .mat-calendar-table th,
  .mat-calendar-table td,
  .mat-calendar-header,
  .mat-calendar-body-label,
  .mat-calendar,
  .mat-datepicker-content .mat-calendar {
    background: admefy-color('dark-gray')!important;
    color: admefy-color('light') !important;
  }

  .mat-datepicker-content .mat-calendar-previous-button:not(.mat-mdc-button-disabled),
  .mat-datepicker-content .mat-calendar-next-button:not(.mat-mdc-button-disabled) {
    color: admefy-color('light')!important;
  }

  .mat-calendar-table-header-divider {
    &::after {
      border-bottom: 1px solid admefy-color('dark-border-gray');
    }
  }

  .mat-mdc-dialog-surface,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: rgba(0, 0, 0, 0.6)!important;
  }

  .media-box-top {
    color: admefy-color('light');
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(100%) sepia(90%) saturate(200%) hue-rotate(45deg);
    cursor: pointer;
    margin: 0;
  }

  .dialog-steps {
    border-radius: 18px;
  }

  admefy-input-edit input {
    background-color: admefy-color('carbon')!important;
  }

  .dialog-steps .mat-mdc-dialog-surface,
  .dialog-steps .mat-mdc-dialog-container .mdc-dialog__surface
  .dialog-steps .mat-mdc-dialog-surface {
    background: admefy-color('dark-gray')!important;
  }

  .generic-card,
  .card-campaign,
  .channel-box {

    .box-header {
      background-color: admefy-color('card-header-dark');
    }
    .info-full {
      background-color: admefy-color('dark');
    }

    .data-padding,
    .data-card,
    .info-channel-box  {
      background-color: admefy-color('dark-gray');
    }

    .info-grid-item {
      background-color: admefy-color('dark');
    }
  }

  textarea,
  input,
  .ng-input input {
    background: transparent!important;
    color: admefy-color('light') !important;
  }

  .panel {
    background:  admefy-color('dark-gray')!important;
    color: admefy-color('light') !important;
  }

  .multi-button {
    background: admefy-color('dark-gray');
    color: admefy-color('light');
  }

  .mdc-text-field {
    padding: 0;
    input {
      margin: 0;
      text-align: left;
      font: normal normal normal 14px / 16px SF Pro Text;
      letter-spacing: 0.14px;
    }
  }

  .mdc-text-field--filled .mdc-line-ripple::after {
    display: none;
  }

  admefy-profile .shadow-overlay {
    background: rgba(0, 0, 0, 0.2);
  }

  .dropdown-content-menu {
    ul {
      li {
        a {
          &:hover {
            background: admefy-color('chat-light')!important;
            color: admefy-color('dark')!important;
            span {
              color: admefy-color('dark')!important;
            }
          }
        }
      }
    }
  }

  .admeet-translate-language,
  .captions {
    background: admefy-color('dark-gray')!important;
    color: admefy-color('light')!important;
  }
  .share-button,
  share-button button {
      background: admefy-color('carbon')!important;
      color: admefy-color('light')!important;
  }

  header .users .dropdown-menu.show {
    mat-expansion-panel-header,
    .menu-item {
      &:hover {
        border-radius: 16px;
        background:#232323;
        span {
          color: admefy-color('light');
        }
      }
    }
    .mat-expansion-panel-body a {
      &:hover {
        span {
          color: admefy-color('green');
        }
      }
    }
  }

  .downloads-grid {
    .item {
      background: admefy-color('light');
      a,
      span {
        color: admefy-color('dark')!important;
      }
    }
  }

  .partners-form select,
  .ngx-datatable .datatable-footer .page-count,
  .comment-author {
    color: admefy-color('light')!important;
  }

  .exchange-icon {
    i {
      &.exchange {
        background-image: url('/assets/cards/exchange-white.svg');
      }
      &.euro {
        background-image: url('/assets/cards/euro-white.svg');
      }
    }
  }

  .data-visualization .card-data,
  .data-campaings .card-campaign {
    background:  admefy-color('dark-gray');
    color: admefy-color('light');
    .card-view {
      background-image: url('/assets/cards/view-eye-white.svg');
    }
    .card-profile {
      background-image: url('/assets/cards/user-white.svg');
    }
    .card-button,
    .campaigns-status .state {
      background: admefy-color('dark');
      color: admefy-color('light');
      span {
        color: admefy-color('light');
      }
    }
  }

  .qr-info .item,
  .data .generic-card {
    background:  admefy-color('dark-gray');
    color: admefy-color('light');
    .card-view {
      background-image: url('/assets/cards/view-eye-white.svg');
    }
    .card-profile {
      background-image: url('/assets/cards/user-white.svg');
    }
    .card-button,
    .status .state {
      background: admefy-color('dark');
      color: admefy-color('light');
      span {
        color: admefy-color('light');
      }
    }
  }

  .summary-content  {
    &__card  {
      background-color: admefy-color('contrast-dark');
    }
  }

  // MODO DARK

  .display-list-popup {
    .close {
      background-image: url('/assets/cards/close-list-white.svg');
    }
    .drop-down {
      background-image: url('/assets/cards/down-white.svg');
    }
    .plus {
      background-image: url('/assets/cards/add-list-white.svg');
    }
  }

  .subscribe-mobile,
  .subscription-button {
    i {
      &.unsuscribe-mobile {
        background-image: url('/assets/responsive/subs-dark.svg');
      }
      &.suscribe-mobile {
        background-image: url('/assets/responsive/subs-green-dark.svg');
      }
    }
    &:hover {
      i {
        background-image: url('/assets/responsive/subs-dark.svg');
      }
    }
  }

  .edit-dvr-btn {
    i {
      background-image: url('/assets/cards/edit-white.svg');
    }
    &:hover {
      i {
        background-image: url('/assets/cards/edit-green.svg');
      }
    }
  }

  .save-dvr-btn {
    i {
      background-image: url('/assets/cards/save-white.svg');
    }
    &:hover {
      i {
        background-image: url('/assets/cards/save-green.svg');
      }
    }
  }

  .save-btn {
    i {
      background-image: url('/assets/lists/save-white.svg');
    }
    &:hover {
      i {
        background-image: url('/assets/lists/save-green.svg');
      }
    }
  }

  .recharge-btn {
    i {
      background-image: url('/assets/lists/recharge-white.svg');
    }
    &:hover {
      i {
        background-image: url('/assets/lists/recharge-green.svg');
      }
    }
  }

  .follower-button {
    i {
      &.unfollow-mobile {
        background-image: url('/assets/responsive/unfollow-dark.svg');
      }
      &.follow-mobile {
        background-image: url('/assets/responsive/follow-green-dark.svg');
      }
    }
    &:hover {
      i {
        background-image: url('/assets/responsive/unfollow-dark.svg');
      }
    }
  }
  .mobile-profile {
    .send-content {
      i {
        &.send-mobile {
          background-image: url('/assets/responsive/send-white.svg');
        }
      }
    }
  }
  .user-links {
    i {
      &.info-mobile {
        background-image: url('/assets/responsive/info-white.svg');
      }
      &.records-mobile {
        background-image: url('/assets/responsive/records-white.svg');
      }
    }
  }

  .media-card-icon i,
  .footer-media .media-type i,
  .sender-info .media i {
    &.message {
      background-image: url('/assets/medias/message-green-white.svg');
    }
    &.video {
      background-image: url('/assets/medias/video-green-white.svg');
    }
    &.social {
      background-image: url('/assets/medias/social-green-white.svg');
    }
    &.stream {
      background-image: url('/assets/medias/stream-green-white.svg');
    }
    &.direct {
      background-image: url('/assets/medias/direct-green-white.svg');
    }
    &.direct_webcam {
      background-image: url('/assets/medias/admeet-green-white.svg');
    }
    &.audio {
      background-image: url('/assets/medias/audio-green-white.svg');
    }
    &.web {
      background-image: url('/assets/medias/web-green-white.svg');
    }
    &.pdf {
      background-image: url('/assets/medias/pdf-green-white.svg');
    }
    &.image {
      background-image: url('/assets/medias/image-green-white.svg');
    }
    &:hover {
      .message {
        background-image: url('/assets/medias/message-white.svg');
      }
      .video {
        background-image: url('/assets/medias/video-white.svg');
      }
      .social {
        background-image: url('/assets/medias/social-white.svg');
      }
      .stream {
        background-image: url('/assets/medias/stream-white.svg');
      }
      .direct {
        background-image: url('/assets/medias/direct-white.svg');
      }
      .direct_webcam {
        background-image: url('/assets/medias/admeet-white.svg');
      }
      .audio {
        background-image: url('/assets/medias/audio-white.svg');
      }
      .web {
        background-image: url('/assets/medias/web-white.svg');
      }
      .pdf {
        background-image: url('/assets/medias/pdf-white.svg');
      }
      .image {
        background-image: url('/assets/medias/image-white.svg');
      }
    }
  }
  .send-media-buttons {
    button {
      .message {
        background-image: url('/assets/medias/message-green-white.svg');
      }
      .video {
        background-image: url('/assets/medias/video-green-white.svg');
      }
      .social {
        background-image: url('/assets/medias/social-green-white.svg');
      }
      .stream {
        background-image: url('/assets/medias/stream-green-white.svg');
      }
      .direct {
        background-image: url('/assets/medias/direct-green-white.svg');
      }
      .direct_webcam {
        background-image: url('/assets/medias/admeet-green-white.svg');
      }
      .audio {
        background-image: url('/assets/medias/audio-green-white.svg');
      }
      .web {
        background-image: url('/assets/medias/web-green-white.svg');
      }
      .pdf {
        background-image: url('/assets/medias/pdf-green-white.svg');
      }
      .image {
        background-image: url('/assets/medias/image-green-white.svg');
      }
      &:hover {
        .message {
          background-image: url('/assets/medias/message-white.svg');
        }
        .video {
          background-image: url('/assets/medias/video-white.svg');
        }
        .social {
          background-image: url('/assets/medias/social-white.svg');
        }
        .stream {
          background-image: url('/assets/medias/stream-white.svg');
        }
        .direct {
          background-image: url('/assets/medias/direct-white.svg');
        }
        .direct_webcam {
          background-image: url('/assets/medias/admeet-white.svg');
        }
        .audio {
          background-image: url('/assets/medias/audio-white.svg');
        }
        .web {
          background-image: url('/assets/medias/web-white.svg');
        }
        .pdf {
          background-image: url('/assets/medias/pdf-white.svg');
        }
        .image {
          background-image: url('/assets/medias/image-white.svg');
        }
      }
      &.active {
        i.message {
          background-image: url('/assets/medias/message-white.svg');
        }
        i.video {
          background-image: url('/assets/medias/video-white.svg');
        }
        i.social {
          background-image: url('/assets/medias/social-white.svg');
        }
        i.stream {
          background-image: url('/assets/medias/stream-white.svg');
        }
        i.direct {
          background-image: url('/assets/medias/direct-white.svg');
        }
        i.direct_webcam {
          background-image: url('/assets/medias/admeet-white.svg');
        }
        i.audio {
          background-image: url('/assets/medias/audio-white.svg');
        }
        i.web {
          background-image: url('/assets/medias/web-white.svg');
        }
        i.pdf {
          background-image: url('/assets/medias/pdf-white.svg');
        }
        i.image {
          background-image: url('/assets/medias/image-white.svg');
        }
      }
    }
  }
  .share-meet-link {
    background-image: url('/assets/admeet/share-admeet-white.svg');
  }
  .disconnect {
    background-image: url('/assets/admeet/disconnect.svg');
  }
  .menu-meet {
    background-image: url('/assets/admeet/menu-green.svg');
  }
  .menu-off {
    background-image: url('/assets/admeet/menu-green-white.svg');
  }
  .share-meet {
    background-image: url('/assets/admeet/share-green.svg');
  }
  .share-off {
    background-image: url('/assets/admeet/share-green-white.svg');
  }
  .captions-on {
    background-image: url('/assets/admeet/captions-green.svg');
  }
  .captions-off {
    background-image: url('/assets/admeet/captions-green-white.svg');
  }
  .camera-meet {
    background-image: url('/assets/admeet/camera-green-white.svg');
  }
  .camera-off {
    background-image: url('/assets/admeet/camera-off.svg');
  }
  .micro-meet {
    background-image: url('/assets/admeet/micro-green-white.svg');
  }
  .micro-off {
    background-image: url('/assets/admeet/micro-off.svg');
  }

  .chat-pane {
    .chat-reactions{
      background-image: url('/assets/chat/reactions-white.svg');
    }
    .chat-emotes {
      background-image: url('/assets/chat/emotes-white.svg');
    }
    .chat-stickers {
      background-image: url('/assets/chat/stickers-white.svg');
    }
    .chat-gifs {
      background-image: url('/assets/chat/gifs-white.svg');
    }
  }

  .display-details-info {
    .item {
      .resolution {
        background-image: url('/assets/cards/resolution-white.svg');
      }
      .views {
        background-image: url('/assets/cards/views-white.svg');
      }
      .time {
        background-image: url('/assets/cards/time-white.svg');
      }
      .geo {
        background-image: url('/assets/cards/geo-white.svg');
      }
    }
  }

  .chat-menu-buttons {
    .reactions-chat {
      background-image: url('/assets/chat/reactions-chat-green-white.svg');
    }
    .dots-chat {
      background-image: url('/assets/chat/dots-chat-green-white.svg');
    }
    .info-chat {
      background-image: url('/assets/chat/info-chat-green-white.svg');
    }
  }

  .main-menu-drop {
    .menu {
      .menu-item,
      a {
        color: #ffffff;

        /* T3 - Regular */
        font-family: SF Pro Text, SF Pro Text;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 23.8px */
        i {
          width: 30px;
          height: 30px;
          display: inline-flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          background-repeat: no-repeat;
          background-position: center center;
          &.profile {
            background-image: url('/assets/menu/profile-white.svg');
          }
          &.admin {
            background-image: url('/assets/menu/admin-white.svg');
          }
          &.channels {
            background-image: url('/assets/menu/channels-white.svg');
          }
          &.contact {
            background-image: url('/assets/menu/contact-white.svg');
          }
          &.info {
            background-image: url('/assets/menu/info-white.svg');
          }
          &.links {
            background-image: url('/assets/menu/links-white.svg');
          }
          &.login {
            background-image: url('/assets/menu/login-white.svg');
          }
          &.logout {
            background-image: url('/assets/menu/logout-white.svg');
          }
          &.moon {
            background-image: url('/assets/menu/moon-white.svg');
          }
          &.sun {
            background-image: url('/assets/menu/sun-white.svg');
          }
          &.policy {
            background-image: url('/assets/menu/policy-white.svg');
          }
        }
        &:active {
          border-radius: 16px;
          background: #374151;
          color: admefy-color('green');
        }
        &:hover {
          border-radius: 16px;
          justify-content: center;
          align-items: center;
          i {
            &.profile {
              background-image: url('/assets/menu/profile-green-white.svg');
            }
            &.admin {
              background-image: url('/assets/menu/admin-green-white.svg');
            }
            &.channels {
              background-image: url('/assets/menu/channels-green-white.svg');
            }
            &.contact {
              background-image: url('/assets/menu/contact-green-white.svg');
            }
            &.info {
              background-image: url('/assets/menu/info-green-white.svg');
            }
            &.links {
              background-image: url('/assets/menu/links-green-white.svg');
            }
            &.login {
              background-image: url('/assets/menu/login-green-white.svg');
            }
            &.logout {
              background-image: url('/assets/menu/logout-red-white.svg');
            }
            &.moon {
              background-image: url('/assets/menu/moon-green-white.svg');
            }
            &.sun {
              background-image: url('/assets/menu/sun-green-white.svg');
            }
            &.policy {
              background-image: url('/assets/menu/policy-white.svg');
            }
          }
        }
      }

      .active-link  {
        color: admefy-color('green')!important;
        i {
          &.profile {
            background-image: url('/assets/menu/profile-green-white.svg');
          }
          &.admin {
            background-image: url('/assets/menu/admin-green-white.svg');
          }
          &.channels {
            background-image: url('/assets/menu/channels-green-white.svg');
          }
          &.contact {
            background-image: url('/assets/menu/contact-green-white.svg');
          }
          &.info {
            background-image: url('/assets/menu/info-green-white.svg');
          }
          &.links {
            background-image: url('/assets/menu/links-green-white.svg');
          }
          &.logout {
            background-image: url('/assets/menu/logout-red-white.svg');
          }
          &.login {
            background-image: url('/assets/menu/login-green-white.svg');
          }
          &.moon {
            background-image: url('/assets/menu/moon-green-white.svg');
          }
          &.sun {
            background-image: url('/assets/menu/sun-green-white.svg');
          }
          &.policy {
            background-image: url('/assets/menu/policy-white.svg');
          }
        }
      }
    }
  }

  .open-map {
    i {
      &.map {
        background-image: url('/assets/sidebar/map-green-white.svg');
      }
    }
  }

  .responsive-creator-grid {
    .channels-icon {
      background-image: url('/assets/responsive/channel-white.svg');
    }
    .screens-icon {
      background-image: url('/assets/responsive/screen-white.svg');
    }
    .subscriptions-icon {
      background-image: url('/assets/responsive/subscription-white.svg');
    }
    .my-user-icon {
      background-image: url('/assets/responsive/perfil-white.svg');
    }
    .reels-icon {
      background-image: url('/assets/responsive/reels-white.svg');
    }
    .social-icon {
      background-image: url('/assets/responsive/social-white.svg');
    }
  }

  .channels-tab,
  .screens-tab,
  .subscriptions-tab,
  .my-user-tab {
    .channels-icon {
      background-image: url('/assets/responsive/channel-white.svg');
    }
    .screens-icon {
      background-image: url('/assets/responsive/screen-white.svg');
    }
    .subscriptions-icon {
      background-image: url('/assets/responsive/subscription-white.svg');
    }
    .my-user-icon {
      background-image: url('/assets/responsive/perfil-white.svg');
    }
    .reels-icon {
      background-image: url('/assets/responsive/reels-white.svg');
    }
    .social-icon {
      background-image: url('/assets/responsive/social-white.svg');
    }
  }

  .btn-gray-mode {
    background: admefy-color('dark-gray')!important;
    i {
      width: 30px;
      height: 30px;
      display: inline-flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      background-repeat: no-repeat;
      background-position: center center;
      &.qr-analytics {
        background-image: url('/assets/sidebar/qr-analytics-green-white.svg');
      }
      &.register {
        background-image: url('/assets/sidebar/register-green-white.svg');
      }
      &.register-channel {
        background-image: url('/assets/sidebar/register-channel-green-white.svg');
      }
      &.send {
        background-image: url('/assets/sidebar/send-green-white.svg');
      }
      &.display-lists {
        background-image: url('/assets/sidebar/display-lists-green-white.svg');
      }
      &.reactions {
        background-image: url('/assets/sidebar/reactions-green-white.svg');
      }
      &.next {
        background-image: url('/assets/cards/next-green-white.svg');
      }

    }
  }

  sidebar-creator-responsive,
  sidebar-creator {
    .menu {
      a {
        i {
          width: 30px;
          height: 30px;
          display: inline-flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          background-repeat: no-repeat;
          background-position: center center;
          &.send {
            background-image: url('/assets/sidebar/send-white.svg');
          }
          &.map {
            background-image: url('/assets/sidebar/map-white.svg');
          }
          &.register {
            background-image: url('/assets/sidebar/register-white.svg');
          }
          &.screens {
            background-image: url('/assets/sidebar/screens-white.svg');
          }
          &.campaigns {
            background-image: url('/assets/sidebar/campaigns-white.svg');
          }
          &.earnings {
            background-image: url('/assets/sidebar/earnings-white.svg');
          }
          &.summary {
            background-image: url('/assets/sidebar/summary-white.svg');
          }
          &.info-view {
            background-image: url('/assets/sidebar/info-view-white.svg');
          }
          &.ref-screens {
            background-image: url('/assets/sidebar/ref-screens-white.svg');
          }
          &.ref-users {
            background-image: url('/assets/sidebar/ref-users-white.svg');
          }
          &.content {
            background-image: url('/assets/sidebar/content-white.svg');
          }
          &.display-lists {
            background-image: url('/assets/sidebar/display-lists-white.svg');
          }
          &.qr-analytics {
            background-image: url('/assets/sidebar/qr-analytics-white.svg');
          }
          &.my-subs {
            background-image: url('/assets/sidebar/my-subs-white.svg');
          }
          &.subscriptors {
            background-image: url('/assets/sidebar/subscriptors-white.svg');
          }
          &.reactions {
            background-image: url('/assets/sidebar/reactions-white.svg');
          }
          &.notifications {
            background-image: url('/assets/sidebar/notifications-white.svg');
          }
          &.developer {
            background-image: url('/assets/sidebar/developer-white.svg');
          }
        }
        &:active {
          border-radius: 16px;
          background: #374151;
          span {
            color: admefy-color('green');
          }

        }
        &:hover {
          border-radius: 16px;
          background: #232323;
          display: inline-flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          i {
            &.send {
              background-image: url('/assets/sidebar/send-green-white.svg');
            }
            &.map {
              background-image: url('/assets/sidebar/map-green-white.svg');
            }
            &.register {
              background-image: url('/assets/sidebar/register-green-white.svg');
            }
            &.screens {
              background-image: url('/assets/sidebar/screens-green-white.svg');
            }
            &.campaigns {
              background-image: url('/assets/sidebar/campaigns-green-white.svg');
            }
            &.earnings {
              background-image: url('/assets/sidebar/earnings-green-white.svg');
            }
            &.summary {
              background-image: url('/assets/sidebar/summary-green-white.svg');
            }
            &.info-view {
              background-image: url('/assets/sidebar/info-view-green-white.svg');
            }
            &.ref-screens {
              background-image: url('/assets/sidebar/ref-screens-green-white.svg');
            }
            &.ref-users {
              background-image: url('/assets/sidebar/ref-users-green-white.svg');
            }
            &.content {
              background-image: url('/assets/sidebar/content-green-white.svg');
            }
            &.display-lists {
              background-image: url('/assets/sidebar/display-lists-green-white.svg');
            }
            &.qr-analytics {
              background-image: url('/assets/sidebar/qr-analytics-green-white.svg');
            }
            &.my-subs {
              background-image: url('/assets/sidebar/my-subs-green-white.svg');
            }
            &.subscriptors {
              background-image: url('/assets/sidebar/subscriptors-green-white.svg');
            }
            &.reactions {
              background-image: url('/assets/sidebar/reactions-green-white.svg');
            }
            &.notifications {
              background-image: url('/assets/sidebar/notifications-green-white.svg');
            }
            &.developer {
              background-image: url('/assets/sidebar/developer-green-white.svg');
            }
          }
        }
      }

      .active-link  {
        background: #374151;
        border-radius: 16px;
        span {
          color: admefy-color('green')!important;
        }
        i {
          &.send {
            background-image: url('/assets/sidebar/send-green.svg');
          }
          &.map {
            background-image: url('/assets/sidebar/map-green.svg');
          }
          &.register {
            background-image: url('/assets/sidebar/register-green.svg');
          }
          &.screens {
            background-image: url('/assets/sidebar/screens-green.svg');
          }
          &.campaigns {
            background-image: url('/assets/sidebar/campaigns-green.svg');
          }
          &.earnings {
            background-image: url('/assets/sidebar/earnings-green.svg');
          }
          &.summary {
            background-image: url('/assets/sidebar/summary-green.svg');
          }
          &.info-view {
            background-image: url('/assets/sidebar/info-view-green.svg');
          }
          &.ref-screens {
            background-image: url('/assets/sidebar/ref-screens-green.svg');
          }
          &.ref-users {
            background-image: url('/assets/sidebar/ref-users-green.svg');
          }
          &.content {
            background-image: url('/assets/sidebar/content-green.svg');
          }
          &.display-lists {
            background-image: url('/assets/sidebar/display-lists-green.svg');
          }
          &.qr-analytics {
            background-image: url('/assets/sidebar/qr-analytics-green.svg');
          }
          &.my-subs {
            background-image: url('/assets/sidebar/my-subs-green.svg');
          }
          &.subscriptors {
            background-image: url('/assets/sidebar/subscriptors-green.svg');
          }
          &.reactions {
            background-image: url('/assets/sidebar/reactions-green.svg');
          }
          &.notifications {
            background-image: url('/assets/sidebar/notifications-green.svg');
          }
          &.developer {
            background-image: url('/assets/sidebar/developer-green.svg');
          }
        }
      }
    }
  }

  .screens-item {
    i {
      background-image: url('/assets/sidebar/screens-white.svg');
    }
  }

  .box-image {
    .send {
      background-image: url('/assets/creator/send-green-white.svg');
    }
    .summary {
      background-image: url('/assets/creator/summary-green-white.svg');
    }
    .register {
      background-image: url('/assets/creator/register-green-white.svg');
    }
    .earnings {
      background-image: url('/assets/creator/earnings-green-white.svg');
    }
  }

  .context-submenu-points {
    background-image: url('/assets/feeds/three-points-white.svg');
  }

  .btn-reactions {
    i {
      background-image: url('/assets/profile-public/plus-white.svg');
    }
  }
  .add-card,
  .add-plus,
  .follow-button {
    i {
      background-image: url('/assets/profile-public/plus-white.svg');
    }

    &:hover {
      i {
        background-image: url('/assets/profile-public/plus-green-white.svg');
      }
    }
  }

  ng-select,
  mat-select,
  form textarea,
  .mat-mdc-tab-body-content input,
  admefy-creator-box form input,
  .emit-date,
  .cdk-drag.media,
  .mdc-checkbox__background,
  .media-body .media,
  editor,
  admefy-users-autocomplete mat-form-field .mdc-text-field--filled {
    border: 1px solid admefy-color('dark-border-gray');
    color: admefy-color('light');

    &::placeholder {
      color: admefy-color('light');
    }
  }

  .landing-header {
    background: admefy-color('dark');
  }

  .admefy-band h1,
  .admefy-band h2,
  .info-texts .item h2,
  .list-benefits h4,
  .info-texts .item .description,
  .info-texts .item h3,
  .info-texts .item h5,
  .info-texts .item .description strong,
  .grid-container .item h3,
  footer .legal,
  footer .license,
  del,
  sup,
  .bio-block {
    color: admefy-color('light') !important;
  }

  .dropdown-content-menu a,
  .dropdown-content-menu a span,
  .mediaForm input,
  .media-title,
  .media-card-subtitle,
  mat-card,
  mat-card-content,
  mat-card-subtitle,
  admefy-media-card .mat-mdc-menu-item .mat-icon,
  .e-profile .devices-button,
  .e-profile span,
  .e-profile input {
    color: admefy-color('light') !important;
  }

  header .users .dropdown-menu.show,
  admefy-search,
  .header-buttons,
  .recommendations,
  .next-events,
  .highlighted,
  .profile-public-send-content,
  admefy-profile-public-user-info,
  admefy-creator-box,
  admefy-media-feed-card,
  admefy-edit-profile .profile,
  .item-display-list,
  .list-content-header,
  .new-screens,
  .search-box,
  .table-custom-box,
  .video-directs,
  .emailVerify,
  .landing-main .grid-container .item,
  .newsletter,
  .admefy-band,
  .request-card,
  .show-info .options,
  .months .month,
  .contact-options .contact-box,
  friend-box,
  .btn-previous,
  mat-form-field input,
  .mat-expansion-panel,
  admefy-media-card-comments .card-comments textarea,
  .info-channel-box,
  .mobile-send-menu .circle,
  admefy-my-earnings table button,
  .follow-card,
  .about-card,
  .wallet-card,
  .mat-mdc-card,
  .selected-displays-submenu,
  admefy-header .selected-displays,
  .notification-submenu,
  .grdp,
  admefy-playlist .card-body,
  .admefy-custom-modalbox,
  .media-wrapper-credentials {
   background-color: admefy-color('contrast-dark')!important;
  }

  div.wrapper,
  img-preload {
    background: admefy-color('dark');
  }

  .vjs-button {
    span {
      color: admefy-color('light')!important;
    }
  }

  .video-js .vjs-volume-level {
    background-color: admefy-color('green')!important;
  }

  .recommendations {
    .image {
      img {
        border: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
  }

  .newsletter input {
    color: admefy-color('light');
    box-shadow: 0px  0px  14px  0px  admefy-color('box-dark')!important;
  }

  admefy-creator-box {
    @media (max-width: 768px) {
      box-shadow: none!important;
    }
  }

  form .ng-select div {
    background: transparent !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder,
  .mat-input-element::placeholder {
    color: admefy-color('light') !important;
    z-index: 3;
  }

  .mat-mdc-tab .mdc-tab__text-label,
  .custom-select-list select {
    color: admefy-color('light') !important;
  }

  .image-text {
    outline: 1px dashed admefy-color('dark-border-gray');
    color: admefy-color('light');
  }

  .mobile-user-menu {
    .background-overlay {
      background: rgb(0 0 0 / 90%) 0% 0% no-repeat padding-box;
    }
  }

  .ngx-datatable.material .datatable-header {
    border: none;
  }

  .language-button,
  .overlay-effect h4,
  .parent-popup .mediaType,
  .context-submenu {
    img {
      filter: invert(1);
    }
  }

  .my-medias .search-media {
    &::placeholder {
      color: admefy-color('light');
    }
  }

  .upload-field,
  .mat-card,
  .filter-button {
    border: 1px solid admefy-color('dark-border-gray');
    color: admefy-color('light');
  }

  .search-box {
    &::-webkit-input-placeholder {
      color: #FFFFFF;
    }
  }

  .notification {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .mat-mdc-tab-label-container,
  .list-content .display,
  .wallet-box .amount,
  .analytics-box .section {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  mat-form-field {
    .mat-input-element {
      caret-color: admefy-color('light');
    }

    .mat-mdc-text-field-wrapper {
      background-color: transparent !important;
    }

    .mdc-text-field__input {
      color: admefy-color('light') !important;
    }
  }

  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: admefy-color('light') !important;
  }

  .login .form-custom-input,
  .register .form-custom-input {
    background-color: admefy-color('light')!important;
    color: admefy-color('dark')!important;
  }

  .name-image {
    background-image:url('/assets/landing-main/name.svg')!important;
    background-repeat: no-repeat!important;
    background-position-y: center!important;
    background-position-x: 10px!important;
    background-size: 22px!important;
   }

  .email-image {
    background-image:url('/assets/landing-main/email.svg')!important;
    background-repeat: no-repeat!important;
    background-position-y: center!important;
    background-position-x: 10px!important;
    background-size: 22px!important;
   }

  .password-image {
    background-image:url('/assets/landing-main/password.svg')!important;
    background-repeat: no-repeat!important;
    background-position-y: center!important;
    background-position-x: 10px!important;
    background-size: 22px!important;
   }

  .profile-image {
    background-image:url('/assets/landing-main/profile.svg')!important;
    background-repeat: no-repeat!important;
    background-position-y: center!important;
    background-position-x: 10px!important;
    background-size: 22px!important;
   }

  .admefy-search-box-autocomplete,
  .admefy-custom-autocomplete {

    &,
    mat-option {
      background: admefy-color('dark') !important;
      color: admefy-color('light') !important;

      .mdc-list-item__primary-text {
        color: inherit;
        display: block;
        width: 100%;
      }

      &:hover:not(.mat-option-disabled),
      &:focus:not(.mat-option-disabled) {
        background: darken($color: adscreen-color('primary'), $amount: 6);
      }

      &.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
        background: darken($color: adscreen-color('primary'), $amount: 9);
      }
    }

  }

  .send-media-buttons button,
  .available-display-list {
    background-color: admefy-color('dark');
    color: admefy-color('light');
  }

  .send-media-buttons button {
    &.active {
      background-color:admefy-color('green')!important;
      color: admefy-color('light')!important;
    }
  }

  .mdc-list-item:hover .mdc-list-item__primary-text {
    color: admefy-color('green');
  }

  .mat-mdc-list-option .mdc-checkbox__background {
    background-color: admefy-color('light');
  }

  .mat-mdc-menu-content {
    background-color: admefy-color('dark');
    color: admefy-color('light');

    &:hover {
      color: admefy-color('green');
    }

    mat-icon {
      color: admefy-color('light')!important;
    }
  }

  .mdc-checkbox__background {
    border-color: rgba(255, 255, 255, 80%) !important;
  }

  mat-panel-title {
    h2 {
      color: #ffffff;
    }
  }

  mat-panel-description {
    color: #ffffff!important;
  }

  .datatable-body {
    overflow-x: hidden;
    .datatable-row-wrapper {
      &:nth-child(odd) {
        background-color: admefy-color('dark-gray');
        border-radius: 18px;
      }

      &:nth-child(even) {
        background-color: admefy-color('dark');
        border-radius: 18px;
      }

      .datatable-body-row {

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .datatable-body-cell {
          color: admefy-color('light');
          text-align: center;
          font-family: SF Pro Text;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.16px;
        }
      }
    }

  }

  admefy-autocomplete {
    img.search-i {
      margin: 7px 10px;
    }
  }

  .faq {
    .mat-expansion-indicator {
      filter: none;
      width: 28px;
      height: 28px;
    }
    mat-expansion-panel-header .mat-expansion-indicator::after {
      padding: 0;
      border: 0;
      width: 28px;
      height: 28px;
      content: url('/assets/landing-main/close.svg');
    }

    mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
      padding: 0;
      border: 0;
      width: 28px;
      height: 28px;
      content: url('/assets/landing-main/plus.svg');
    }

    .mat-expanded {
      background: admefy-color('contrast-dark')!important;
    }

  }

  admefy-chat .chat-pane__chat-box--user {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: admefy-color('contrast-dark');
  }

  .parent-popup {
    background: admefy-color('dark-gray')!important;
    span,
    .display-name,
    .display-location,
    .cat,
    .title,
    .description,
    .price {
      color: #ffffff!important;
    }
  }

  .display-list-popup {
    .displays,
    .lists .list {
      background: admefy-color('dark')!important;
    }
  }

  .create-list,
  .display-list-popup {
    background: admefy-color('dark-gray')!important;
  }

  .header-profile-brand {
    .user-info {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  .driver-popover.driverjs-theme {
    background-color: admefy-color('green');
    color: admefy-color('dark-gray');
  }

  .driver-popover.driverjs-theme .driver-popover-title {
    font-size: 20px;
  }

  .driver-popover.driverjs-theme .driver-popover-title,
  .driver-popover.driverjs-theme .driver-popover-description,
  .driver-popover.driverjs-theme .driver-popover-progress-text {
    color: admefy-color('dark-gray');
  }

  #driver-popover-title {
    border: none!important;
    box-shadow: none!important;
  }

  .driver-popover.driverjs-theme button {
    flex: 1;
    text-align: center;
    background-color: transparent;
    color: admefy-color('dark-gray');
    border-color: admefy-color('dark-gray');
    text-shadow: none;
    font-size: 14px;
    border-radius: 6px;
    margin: 10px;
  }

  .driver-popover.driverjs-theme .driver-popover-navigation-btns {
    justify-content: space-between;
  }

  .driver-popover.driverjs-theme .driver-popover-close-btn {
    color: admefy-color('dark-gray');
    border: none;
  }

  .driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
    border-left-color: admefy-color('green');
  }

  .driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
    border-right-color: admefy-color('green');
  }

  .driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
    border-top-color: admefy-color('green');
  }

  .driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
    border-bottom-color: admefy-color('green');
  }

  .show-reactions,
  .wallet-alert {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .cdk-overlay-pane {
    box-shadow:  0px 0px 7px 0px rgb(62 62 62) !important;
  }

  .social-slogan {
    h3 {
      color: admefy-color('light')!important;
    }
  }

  .footer-list {
    color: admefy-color('light')!important;
  }
}

