/* Colors */

$adscreen-colors: (
  'primary': #252147,
  'border': #9f9f9f,
  'text': #545454,
  'success': #1FC448,
  'info': #0483DD,
  'warning': #C68100,
  'danger': #E23731,
  'inputs':#495057,
  'hovertext': #ffffff,
  'google': #D34836,
  'facebook': #3b5998,
  'jumbotron': #ffffff,
  'bluemarine': #252147,
  'darkgrey': #f1f1f1,
  'lightgrey': #f5f5f5,
  'white': #ffffff,
  'pink': #D33C6E,
  'popupback': rgba(30, 28, 70, 0.92),
  'vimeo': #00adef,
  'youtube':#ff0000,
  'android':#a5c736,
  'login-color-primary': #37bde1,
  'login-color-secondary': #D33C6E,
  'login-grey': #575756,
  'business': #35bbe0
);

@function adscreen-color($color: 'primary') {
  @return map-get($adscreen-colors, $color);
}

$admefy-colors: (
  'dark-border': #484848,
  'dark-border-gray': #ffffff33,
  'dark-light': #d3cdcd,
  'hover-light': #F3F4F6,
  'gray': #9e9a9a,
  'dark':#000000,
  'light': #ffffff,
  'pink': #D33C6E,
  'red': #c71d1d,
  'green': #37D39A,
  'hover-gray': #EDEBF0,
  'dark-gray': #232323,
  'carbon': #374151,
  'carbon-light': #6C7481,
  'blue': #20BCDC,
  'box-dark': rgb(70, 70, 70),
  'box-light': rgb(217, 217, 217),
  'placeholder-light': #A7A3AD,
  'contrast-dark': #121112,
  'chat-light': #f1f0f2,
  'card-header-light': #D9D9DA,
  'card-header-dark': #363636
);

@function admefy-color($color: 'dark') {
  @return map-get($admefy-colors, $color);
}

@import './theme-dark.scss';
@import './theme-light.scss';


:root {
  --mdc-form-field-label-text-color: white !important;
  --mdc-outlined-text-field-input-text-color: white;
  --mdc-filled-text-field-label-text-color: white;
  --mdc-filled-text-field-input-text-color: white;
  --mat-select-placeholder-text-color: white;
  --mat-select-enabled-trigger-text-color: white;
  --ng-progress-color: #37D39A;
}

html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: white;
  --mdc-form-field-label-text-color: white !important;
  --mat-icon-button-state-layer-color: white;
}


::selection {
  color: white;
  background: #37D39A;
}
