admefy-map-gl {
  .marker-display {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    background-color: rgb(255, 255, 255);
    border-radius: 28px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.18) 0px 2px 4px;
    color: rgb(34, 34, 34);
    height: 28px;
    padding: 0px 8px;
    transform: scale(1);
    transform-origin: 50% 50% 0px;
    transition: transform 250ms cubic-bezier(0, 0, 0.1, 1) 0s;
    font-weight: bold;
    text-align: center;
    min-width: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: white;
      background: admefy-color('green');
      z-index: 99999;
    }
  }

  .mapboxgl-ctrl {
    @media (max-width: 768px) {
      right: 30px;
    }
  }

  .marker-display-stream {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    border-radius: 28px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.18) 0px 2px 4px;
    background-color: #e7246e;
    color: #fff;
    height: 28px;
    padding: 0px 8px;
    transform: scale(1);
    transform-origin: 50% 50% 0px;
    transition: transform 250ms cubic-bezier(0, 0, 0.1, 1) 0s;
    font-weight: bold;
    text-align: center;
    min-width: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: white;
      background: grey;
      z-index: 99999;
    }

  }

  .marker-display-ds {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    background-color: admefy-color('green');
    color: #fff;
    border-radius: 28px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.18) 0px 2px 4px;
    height: 28px;
    padding: 0px 8px;
    transform: scale(1);
    transform-origin: 50% 50% 0px;
    transition: transform 250ms cubic-bezier(0, 0, 0.1, 1) 0s;
    font-weight: bold;
    text-align: center;
    min-width: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: white;
      background: grey;
      z-index: 2;
    }
  }

  .marker-display-external {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    background-color: #f0cd5c;
    color: #000;
    border-radius: 28px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.18) 0px 2px 4px;
    height: 28px;
    padding: 0px 8px;
    transform: scale(1);
    transform-origin: 50% 50% 0px;
    transition: transform 250ms cubic-bezier(0, 0, 0.1, 1) 0s;
    font-weight: bold;
    text-align: center;
    min-width: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: white;
      background: grey;
      z-index: 2;
    }
  }

  .marker-display-active {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    background-color: yellowgreen;
    border-radius: 28px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.18) 0px 2px 4px;
    color: #000;
    height: 28px;
    padding: 0px 8px;
    transform: scale(1);
    transform-origin: 50% 50% 0px;
    transition: transform 250ms cubic-bezier(0, 0, 0.1, 1) 0s;
    font-weight: bold;
    text-align: center;
    min-width: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: white;
      background: grey;
      z-index: 2;
    }
  }

  .mapboxgl-popup {
    max-width: 320px !important;

    @media (max-width: 468px) {
      width: 100% !important;
      transform: none !important;
      position: relative;
      top: 0;
      height: 100%;
      z-index: 3;
      min-width: 100%;
      border-radius: 0;

      .mapboxgl-popup-tip {
        display: none;
      }

      .mapboxgl-popup-content {
        border-radius: 0;
        padding: 0 !important;
      }
    }
  }

  .mapboxgl-popup-content {
    border-radius: 10px;
    opacity: 1;
    overflow: hidden;
    background: transparent;
    padding: 0 !important;
  }

  .mapboxgl-popup-close-button {
    right: 5px !important;
    top: 5px !important;
    color: #374151!important;
    font-weight: 250;
    font-size: 35px;
    width: 28px;
    height: 28px;
    background-color: #ffffff;
    border-radius: 100%;
    text-align: center;
    padding: 0;
    font-family: SF Pro Text;
    &:hover {
      background-color: #ffffff;
    }
  }
}
