/* Imports */
@import 'ngx-toastr/toastr';
@import "chartist/dist/index.scss";
@import 'theme.scss';
@import 'cards.scss';
@import 'mapbox-custom.scss';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import '@fortawesome/fontawesome-free/css/v4-font-face.min.css';
@import '@fortawesome/fontawesome-free/css/v4-shims.min.css';



@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFProDisplay-Light.woff2') format('woff2'),
    url('/assets/fonts/SFProDisplay-Light.woff') format('woff'),
    url('/assets/fonts/SFProDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFProDisplay-Heavy.woff2') format('woff2'),
    url('/assets/fonts/SFProDisplay-Heavy.woff') format('woff'),
    url('/assets/fonts/SFProDisplay-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('/assets/fonts/SFProDisplay-Bold.woff') format('woff'),
    url('/assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
    url('/assets/fonts/SFProDisplay-Ultralight.woff') format('woff'),
    url('/assets/fonts/SFProDisplay-Ultralight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('/assets/fonts/SFProDisplay-Regular.woff') format('woff'),
    url('/assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('/assets/fonts/SFProDisplay-Medium.woff') format('woff'),
    url('/assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
    url('/assets/fonts/SFProDisplay-Semibold.woff') format('woff'),
    url('/assets/fonts/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SFProText-Black.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Black.woff') format('woff'),
    url('/assets/fonts/SFProText-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SFProDisplay-Thin.woff2') format('woff2'),
    url('/assets/fonts/SFProDisplay-Thin.woff') format('woff'),
    url('/assets/fonts/SFProDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SFProText-Ultralight.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Ultralight.woff') format('woff'),
    url('/assets/fonts/SFProText-Ultralight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SFProText-Heavy.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Heavy.woff') format('woff'),
    url('/assets/fonts/SFProText-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SFProText-Regular.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Regular.woff') format('woff'),
    url('/assets/fonts/SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SFProText-Bold.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Bold.woff') format('woff'),
    url('/assets/fonts/SFProText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SFProText-Thin.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Thin.woff') format('woff'),
    url('/assets/fonts/SFProText-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SFProText-Light.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Light.woff') format('woff'),
    url('/assets/fonts/SFProText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SFProText-Semibold.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Semibold.woff') format('woff'),
    url('/assets/fonts/SFProText-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SFProText-Medium.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Medium.woff') format('woff'),
    url('/assets/fonts/SFProText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



html {
  height: 100%;
  margin: 0;
}

body {
  overflow: hidden;
  font-family: SF Pro Text, sans-serif, -apple-system, system-ui, BlinkMacSystemFont, Helvetica, Arial;
  font-weight: 400;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.overlay-map-dialog {
  background: transparent !important;
  max-width: 100% !important;

  .mat-mdc-dialog-container {
    padding: 0 !important;
    height: 100vh !important;
  }
}

.swiper-pagination-bullet-active {
  color: #000000;
  background-color: #000000;
}

.custom-video-info-dialog {
  background: transparent !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  //background: transparent !important;
  box-shadow: none !important;
}

.popup-close-button {
  position: absolute;
  right: 30px;
}

.plyr__tooltip {
  width: 60px;
}

.modal-header {
  justify-content: flex-end;
}

.container-new {
  width: 1232px;
  max-width: 100%;
  margin: 0 auto;
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(2px);

  @media (max-width: 768px) {
    backdrop-filter: blur(4px);
  }
}

.mat-mdc-dialog-surface {
  border-radius: 20px!important;
}

.overlay-map-dialog .mat-mdc-dialog-surface {
  border-radius: 0!important;
}

.cdk-overlay-pane {
  background: transparent !important;
  border-radius: 20px;

  a {
    text-decoration: none;

    &:hover {
      color: deeppink;
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    height: auto;
    border-radius: 20px;

    &:not(.storage-dialog) {
      background: transparent;
    }
  }
}

admefy-verify-email {
  height: 100vh !important;
}

.mdc-checkbox__checkmark {
  background-color: admefy-color('green');
}

.pagination-previous,
.pagination-next {
  background: admefy-color('green') 0% 0% no-repeat padding-box;
  border-radius: 5px !important;
  text-align: center;
  font: normal normal normal 12px/14px SF Pro Text !important;
  letter-spacing: 0.12px;
  width: auto;
  border: 0;
  cursor: pointer !important;

  a {
    font: normal normal normal 12px/14px SF Pro Text !important;
    letter-spacing: 0.12px !important;
    background: none !important;
    text-decoration: none;
  }
}

.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-previous.disabled:before,
.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-next.disabled:after {
  margin: 0 !important;
}

.ngx-pagination .disabled {
  background-color: #211e40;
  color: gray;
  opacity: 0.2;
}

/* width */
html ::-webkit-scrollbar {
  width: 4px;

  @media (max-width: 478px) {
    height: 12px !important;
  }
}

/* Track */
html ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 18px;
}

/* Handle */
html ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .8);
  border-radius: 18px;
}

/* Handle on hover */
html ::-webkit-scrollbar-thumb:hover {
  opacity: 0.8;
}

body.invisibleScroll {
  overflow-y: hidden;
}

ngb-modal-window .modal-content {
  border-radius: 18px !important;

  .close {
    margin: 5px;
  }
}

input-money input {
  border-radius: 20px!important;
}

.color-pink {
  color: adscreen-color('pink');
}

.modal-dialog {
  margin: 0 auto !important;
  // min-width: 70%;
}

.video-presentation .plyr {
  height: auto !important;
}

.video-js .vjs-tech {
  background-color: #000000;
}

.card .card-body {
  max-height: none !important;
}

.table-custom-box {
  border-radius: 4px;
  margin: 10px 0;
  max-width: 95vw;
  overflow: hidden;

  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
}

.badge-default {
  background-color: adscreen-color('primary');
  color: #ffffff;
  border: 10px;
  padding: 2px 10px;
  line-height: inherit;
}

.back-link {
  border-radius: 100%;
  padding: 13px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;

  &:hover,
  &:focus {
    background-color: adscreen-color('primary');
    color: adscreen-color('white');
  }
}

input.with-box-shadow,
select.with-box-shadow {
  border: 1px solid adscreen-color('border');
  box-shadow: 0 2px 6px -4px rgba(0, 0, 0, .2), 0 8px 10px -4px rgba(0, 0, 0, .14), 0 8px 12px 4px rgba(0, 0, 0, .05);
  color: adscreen-color('inputs');
  font-weight: 400;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.btn:focus,
div {
  outline: none;
}

.open>a {
  outline: 0;
}

.ngx-pagination .current {
  background-color: #D33C6E !important;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  padding: 2px !important;
}

.break-all {
  word-break: break-all;
}

.ui-select-match span,
.ui-select-match span:focus,
.ui-select-match span:active,
.ui-select-match span:hover {
  background: #ffffff !important;
  color: #495057 !important;
}

.ui-select-match i {
  display: none;
}

.container90 {
  max-width: 1320px !important;
  width: 90%;
  margin: 0 auto;
}

.left-0 {
  left: 0;
}

.left-auto {
  left: auto;
}

.ng-select.ng-select-single .ng-select-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px -4px rgba(0, 0, 0, .2), 0 8px 10px -4px rgba(0, 0, 0, .14), 0 8px 12px 4px rgba(0, 0, 0, .05);
  color: adscreen-color('inputs');
  font-weight: 400;
}

#profile_picture {
  padding: 3px;
}

.container60 {
  width: 60%;
  margin: 0 auto;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.container70 {
  width: 70%;
  margin: 0 auto;

  @media (max-width: 992px) {
    width: 100%;
  }
}

// Pantallas grandes
@media (min-width: 1440px) {
  .container90 {
    max-width: 75%;
  }
}

// Tablets
@media (max-width: 768px) {
  .container90 {
    margin: 0 5%;
  }
}

/* Common */
.pt0 {
  padding-top: 0;
}

.mt100 {
  margin-top: 100px;
}

.mt80 {
  margin-top: 80px;
}

.mt50 {
  margin-top: 50px;
}

.mt30 {
  margin-top: 30px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.ml15 {
  margin-left: 15px;
}

.mr15 {
  margin-right: 15px;
}

h1,
.container h1 {
  font-weight: 800;
  font-size: 30px;
  letter-spacing: 1px;
}

.container h2 {
  font-size: 18px;
}

.results {
  padding: 5px;
  text-align: center;
}

ngb-modal-window {
  .modal-header {
    justify-content: space-between;
  }

  .modal-dialog {
    width: 50%;
    @media (max-width: 768px) {
      width: 95%;
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-content {
    .subscribe {
      border: none;
      border-radius: 12px;
      padding: 4px 8px;
    }

    img {
      border-radius: 12px;
      margin-right: 10px;
    }
  }

}

/* botones */
.btn-custom {
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
  min-width: 90px;
  transition: all .1s cubic-bezier(.16, .8, .45, 1);
  white-space: nowrap;
  font-weight: 700;
}

.btn {

  &:hover,
  &:focus,
  &:active,
  &.active {
    outline: none;
    box-shadow: none;
  }
}

.btn-primary,
.dropdown-item.active,
.dropdown-item:active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: admefy-color('green');
  border-color: admefy-color('green');

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: admefy-color('green') !important;
    border-color: admefy-color('green') !important;
  }

  &.disabled,
  &:disabled {
    background-color: admefy-color('green') !important;
    border-color: admefy-color('green') !important;
  }

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  box-shadow: none !important;
}

/* media queries */
@media (max-width: 500px) {
  header .logo h1 {
    display: none;
  }

  .sidebar {
    max-width: 100%;
  }
}

.bg-success,
.badge-success {
  background-color: adscreen-color('success');
}

.btn-primary {
  color: white !important;
}

// Marca de agua player
a[href^="https://flowplayer"] {
  visibility: hidden !important;
}


/* default cursor for screen */
.flowplayer.is-ready .fp-ui {
  cursor: default;
}

/* re-instate pointer cursor for controls
 * and header control elements
 * plus entirely in finished state for replay
 */
.flowplayer.is-ready .fp-controls,
.flowplayer.is-ready .fp-header>*,
.flowplayer.is-finished .fp-ui {
  cursor: pointer;
}

/* do not show play/pause button in controlbar */
.flowplayer .fp-playbtn {
  display: none;
}

/* do not show on-screen pause action indicator on finish */
.flowplayer {

  .fp-pause,
  .fp-play.fp-visible {
    display: none;
  }
}

.fp-ui {
  display: none !important;
}

.pretty.p-default input:checked~.p-asw.state {
  border-color: adscreen-color('primary') !important;

  label:after {
    background-color: adscreen-color('primary') !important;
  }
}

.modal-open .modal {
  display: flex !important;
  backdrop-filter: blur(11px);
}

.custom-close {
  position: absolute;
  right: 0;
  outline: none;
  padding: 2px 5px;
  z-index: 99999;
}

.password-toogle {
  position: relative;
  bottom: 40px;
  right: 10px;
  float: right;
  cursor: pointer;
}

.check-input {
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  transform: translateY(-50%);
  cursor: pointer;
}

.player .plyr__video-embed iframe,
.player .plyr--video video {
  // height: 100vh!important;

  @media (max-width: 992px) {
    top: 50%;
    transform: translateY(-50%);
    height: 100% !important;
  }

}

.player .plyr__poster {
  display: none !important;
}

::ng-deep media-video-player .wrapper.player-centered .wrapper-message {
  border: 0 !important;
}

@media (max-width: 375px) {
  .plyr__time {
    display: none !important;
  }
}

.fr-box.fr-basic .fr-element {
  padding: 30px;
}

.swal2-container {
  z-index: 9999 !important;

  .swal2-modal {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    max-width: 410px;
    width: 100%;

    .swal2-title {
      text-align: center;
      font: normal normal 600 22px/26px SF Pro Text;
      letter-spacing: 0.22px;
      color: admefy-color('green');
    }

    .swal2-html-container {
      text-align: center;
      font: normal normal normal 14px/19px SF Pro Text;
      letter-spacing: 0.14px;
      opacity: 1;
    }

    .swal2-icon.swal2-warning {
      color: admefy-color('green');
      border-color: admefy-color('green');
    }

    .swal2-input {
      font: normal normal normal 14px/19px SF Pro Text;
      letter-spacing: 0.14px;
    }

    .swal2-styled.swal2-cancel,
    .swal2-styled.swal2-confirm {
      background: admefy-color('green') 0% 0% no-repeat padding-box !important;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      opacity: 1;
      height: 31px;
      text-align: center;
      font: normal normal normal 16px/19px SF Pro Text;
      letter-spacing: 0.16px;
      color: #252146;
      padding: 0px 15px;

      &:hover {
        transition: 0.5ms ease-in-out all;
        opacity: 0.8;
      }
    }

    .swal2-styled.swal2-cancel {
      background: transparent!important;
      box-shadow: none;
      border: none;
    }
  }
}



@for $i from 1 through 5 {
  .flex-#{$i} {
    flex: $i;
  }
}

@for $i from 1 through 100 {
  .z-#{$i} {
    z-index: $i;
  }
}

.leaflet-popup-content {
  min-width: 120px;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.leaflet-bottom,
.leaflet-top {
  z-index: 1 !important;
}

.plyr {
  min-width: 256px !important;
  height: 100%;
}

.modal-open {
  .plyr {
    min-width: 800px !important;

    @media(max-width: 992px) {
      min-width: 496px !important;
    }

    @media(max-width: 768px) {
      min-width: 476px !important;
    }

    @media(max-width: 476px) {
      min-width: 320px !important;
    }

  }
}

.social-share a {
  font-size: 30px;
}

.no-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
}

.shadow-none {
  box-shadow: none !important;
}

.virtual-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
  // width: 0;
  // height: 0;
}

.h-80 {
  height: 80% !important;
}

.h-auto {
  height: auto !important;
}

.h-inherit {
  height: inherit !important;
}


button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  opacity: 1;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
}

.admefy-channel-info-modalbox {
  height: auto;
  width: 386px !important;
  border-radius: 20px;

  .wrapper-image {
    width: 386px !important;
    height: 240px !important;

    img {
      object-fit: contain;
    }
  }

  .channel-image {
    overflow: hidden;
  }
}

.overlay-login-box {
  background: transparent !important;

  @media (max-width: 768px) {
    max-width: 100% !important;

    .close {
      z-index: 99999;
    }
  }
}

.help-menu {
  padding: 0;

  i {
    font-size: 25px;
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  button {
    border-bottom: 1px solid admefy-color('green');
    margin: 0 auto;
    display: block;

    &:last-child {
      border: none;
    }
  }
}

button.loading {
  position: relative;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner 0.8s linear infinite;
  }
}


ngx-datatable.ngx-datatable.material {
  box-shadow: none;

  &.admefy-theme {
    padding: 0;
    border: none;
    border-radius: 10px;

    &:not(.cell-selection) .datatable-body-row:hover,
    &:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
      background-color: admefy-color('green');
      border-radius: 18px;
    }

    .datatable-header {
      height: auto !important;

      .resize-handle {
        border: none;
      }

      .datatable-header-cell {
        background: transparent;
        text-align: center;
        font-family: SF Pro Text;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.18px;
        text-transform: uppercase;

        &:last-child {
          border-right: none;
        }

        .sort-btn {
          display: none;
        }
      }
    }

    .datatable-footer {
      border: none;
    }
  }
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.float-right {
  float: right !important;
}

.badge {
  text-decoration: none;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    background: admefy-color('dark-gray');
    color: admefy-color('green');
  }
}

.badge-info {
  background: admefy-color('dark-gray');
  color: admefy-color('green');
}

.mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
  color: admefy-color('green');
  box-shadow: none;
}

.container {
  max-width: 1140px;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

a {
  text-decoration: none;
}

mat-form-field .mdc-text-field--filled {
  background-color: transparent !important;
}

.text-left {
  text-align: left !important;
}


mat-stepper .mat-horizontal-stepper-wrapper {
  height: inherit;

  // .mat-horizontal-content-container {
  //   overflow-x: hidden !important;
  //   overflow-y: auto !important;
  // }

  .mat-horizontal-content-container,
  .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
    height: 100%;
  }

}

.dialog-steps {
  max-height: 700px;
  height: auto!important;

  @media (max-width: 768px) {
    max-height: 100%;
  }

  admefy-send-media-campaign {
    padding: 0!important;
  }
}

.dialog-steps .mat-mdc-dialog-surface {
  position: static !important;
  height: auto!important;
}

admefy-creator-map {
  .map-wrapper {
    height: 100%!important;
    max-width: 100%!important;
    margin: 50px;
    admefy-map-gl {
      canvas {
        border-radius: 20px;
      }
    }
  }
}

mat-selection-list {
  max-height: 400px;
}

.cursor-pointer {
  cursor: pointer;
}

button.unstyled {
  background: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}


.btn-google {
  background-color: adscreen-color('google') !important;
  border: 0;
}

.btn-facebook {
  background-color: adscreen-color('facebook') !important;
  border: 0;
}

.back-link-button {
  display: none;
  background: transparent;
  border: 0;
  position: absolute;
  right: 30px;
  top: 20px;
  z-index: 1;

  @media (max-width: 768px) {
    display: block;
    right: 0;
    top: 15px;
  }
}

.btn-pink {
  background-color: adscreen-color('pink') !important;
  color: #ffffff!important;
  transition: 0.4ms all ease-in-out;
  opacity: 1;
  cursor: pointer;
  i {
    color: #ffffff!important;
  }

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    pointer-events: none;
    background-color: adscreen-color('border') !important;
  }
}

.btn-default {
  width: 100%;
  background-color: adscreen-color('primary') !important;
  border-radius: 8px;
  font-size: 14px;
  color: #ffffff;
  transition: 0.4ms all ease-in-out;
  opacity: 1;
  font-weight: 800;

  &:hover {
    background-color: adscreen-color('pink') !important;
  }
}

.btn-next {
  background: admefy-color('carbon') 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  font: normal normal normal 16px/19px SF Pro Text;
  letter-spacing: 0.16px;
  color: admefy-color('light') !important;
  width: auto;
  padding: 8px 20px;
  border: 0;
  transition: 0.3s ease-in-out all;

  span {
    color: admefy-color('light') !important;
  }

  &:hover {
    background: admefy-color('green');
  }
}

.btn-reactions {
  background: admefy-color('dark-gray') 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  font: normal normal normal 16px/19px SF Pro Text;
  letter-spacing: 0.16px;
  color: admefy-color('light') !important;
  width: auto;
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  height: 46px;
  gap: 12px;
  border: 0;
  transition: 0.3s ease-in-out all;

  span {
    color: admefy-color('light') !important;
    display: inline-flex;
    align-items: center;
  }

  &:hover {
    background: admefy-color('green');
  }

  i {
    display: inline-flex;
    width: 30px;
    height: 30px;
    padding: 2.5px;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 12px;
  }
}

@keyframes rotate-btn-more {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-120deg);
  }
}

.btn-load-more {
  background: admefy-color('carbon') 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  text-align: center;

  /* T3 - Regular */
  font-family: SF Pro Text;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 23.8px */
  color: admefy-color('light') !important;
  width: auto;
  height: 46px;
  padding: 8px 16px;
  border: 0;
  transition: 200ms ease-in-out all;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  i {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/assets/cards/load-more-white.svg');
    margin-right: 10px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: admefy-color('light') !important;
  }

  &:hover {
    i {
      background-image: url('/assets/cards/load-more-green-white.svg');
      animation: rotate-btn-more 0.2s linear;
      transform: rotate(-120deg);
    }
    span {
      color: admefy-color('green')!important;
    }
  }
}

.btn-danger {
  background: admefy-color('pink') 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  font: normal normal normal 16px/19px SF Pro Text;
  letter-spacing: 0.16px;
  color: admefy-color('light') !important;
  width: auto;
  padding: 8px 20px;
  border: 0;
  transition: 0.3s ease-in-out all;

  span {
    color: admefy-color('light') !important;
  }

  &:hover {
    background: admefy-color('green');
  }
}

.btn-previous {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  font: normal normal normal 16px/19px SF Pro Text;
  letter-spacing: 0.16px;
  color: admefy-color('light');
  width: auto;
  padding: 8px 20px;
  border: 0;
}

.reset-btn {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

.pretty.p-switch.p-fill input:checked~.state.p-success:before {
  background-color: admefy-color('green')!important;
  border-color: admefy-color('green') !important;
}

.bg-grey-100 {
  background-color: #FBFBFA;
}

.jumbotron {
  background-color: adscreen-color('jumbotron') !important;
}


.border-bottom {
  border-bottom: 0.1rem solid #DCDEDD;
}

.border-bottom-none-md {
  @media (min-width: 795px) {
    border-bottom: none !important;
  }
}

.common-box {
  border-radius: 10px;
  margin: 40px auto !important;
  display: block;
  padding: 15px 30px;
}


.border-right-sm {
  @media (min-width: 600px) {
    border-right: 0.1rem solid #DCDEDD;
  }
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.line-height-heading {
  line-height: 1.2 !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-pink {
  color: adscreen-color('pink') !important;
}

.border-radius-rounded {
  border-radius: 9000px !important;
}

h5,
.f5,
.responsive-media figcaption {
  font-size: 14px;
  line-height: 20px;
}

.f6 {
  font-size: 12px;
}

.dark-grey-400 {
  color: #9B9E9E;
}

.bg-grey-200 {
  background-color: #F7F7F6;
}

.link-cobalt-500 {
  color: #4C6CF8;
}

.text-strikethru {
  position: relative;
  text-align: center;

  .line {
    height: 1px;
    background-color: #F1EEEA;
    width: 100%;
  }

  .text {
    display: inline-block;
    background-color: #FFFFFF;
    padding: 5px 10px;
    font-size: 14px;
    top: -16px;
    position: relative;
    color: #282828;
  }
}

.offscreen {
  left: -999em;
  position: absolute;
}

.page-item.active .page-link {
  background-color: adscreen-color() !important;
  border-color: adscreen-color() !important;
}

.carousel-item.active {
  border: 0;
}

// Player Plyr
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: admefy-color('green');
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 5px rgba(admefy-color('green'), .5);
}

.plyr__control--overlaid {
  background: rgba(admefy-color('green'), .8);
}

.plyr--full-ui input[type=range] {
  color: admefy-color('green')
}


.terms {
  a {
    color: #D4D0DB !important;
    font-weight: 700;
  }
}


.notification:hover .message {
  a {
    color: adscreen-color('pink') !important;
  }
}


.notification .message {
  a {
    font-weight: bold;
  }
}

.hide-bar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    width: 0px !important;
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    display: none;
    visibility: hidden;
    border-radius: 0;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cdk-global-overlay-wrapper {
  justify-content: center !important;
  align-items: center !important;
}

.admefy-custom-modalbox {
  width: 100vw;
  height: 100vh;
  max-width: 960px !important;

  @media (min-width: 768px) {
    width: 50% !important;
    height: auto !important;
  }

  @media (max-width: 768px) {
    border-radius: 30px;
    top: 50px;
    position: absolute !important;

    mat-label {
      font-size: 14px;
    }

    .close {
      right: 15px;
      top: 15px;
    }
  }

  .mat-mdc-dialog-container {
    border-radius: 18px;
    opacity: 1;
    backdrop-filter: blur(20px);
    border-radius: 10px;

    @media (max-width: 768px) {
      background: transparent;
      backdrop-filter: none;
    }
  }


  .mat-dialog-content {
    max-height: initial !important;
  }
}

.admefy-channel-info-modalbox {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: calc(100vh - 100px);
  overflow-y: auto;
  max-height: 100%;
  z-index: 99;
  pointer-events: all;
  background: #181818;
  border-radius: 20px;
  max-width: 386px !important;

  @media (max-width: 768px) {
    max-width: 660px;
    width: 90vw !important;
  }

  .close {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 1;
    display: inline-flex;
    padding: 9px 12px;
    font-size: 19px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px 0.8em;
    background: #141414;
    color: #fff;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
    outline: none;
    width: 40px;
    height: 40px;
  }

  .mat-mdc-dialog-container {
    background: #242244;
    fill: #242244;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
    color: white;
    padding: 0;
  }

  .mat-dialog-content {
    max-height: initial !important;
  }
}

.cdk-drag.media {
  height: 220px;
  width: 300px;
  max-width: 300px;
  margin: 5px;
  margin-right: 5px;
  box-shadow: none;
  border-radius: 6px;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  flex: 1 1 auto;
  transform: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
  cursor: pointer;

  .button-submenu {
    background: none !important;
    border: none !important;
    color: grey !important;
    outline: none !important;

    &:focus {
      outline: none !important;
    }
  }

  .context-submenu.active {
    display: block;
    position: absolute !important;
    top: 0;
    padding: 0px;
    font-size: 11px;
    margin: 5px auto;
    width: 100%;
    z-index: 10;
  }

  .media-info {
    span {
      padding: 5px;
      display: block;
      margin: 5px;
    }
  }

  .overlay {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;

    &.activeMedia {
      position: relative !important;
      height: 100% !important;
      z-index: 1 !important;
      background-color: rgba(0, 255, 0, 0.4) !important;

    }

    img {
      max-width: 20px;
      left: 15px;
      top: 15px;
      position: absolute;
      z-index: 10;
    }

  }

  .remove {
    right: 15px;
    top: 15px;
    position: absolute;
    z-index: 10;
    color: red;
  }

  &.select {
    background-color: lightgreen !important;
    color: #000000 !important;
  }

  .content-media {
    flex: 1 1 auto;
    position: relative;
    width: 100%;
    height: 170px;

    >div {
      height: 100%;
      background-color: #fff;
      border-radius: 1px;
      left: 0;
      overflow: hidden;
      position: absolute;
      text-align: center;
      top: 0;
      transition: opacity 200ms ease-in-out;
      width: 100%;

      img {
        height: auto;
        width: 100%;
      }
    }

    ::ng-deep img-preload img {
      max-width: 100% !important;
    }
  }

  .footer-media {
    display: flex;
    flex: 0 0 48px;
    width: 100%;

    .media-text {
      flex: 1 1 auto;
      font-weight: 500;
      overflow: hidden;
      padding-right: 12px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 56px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .media-type {
      flex: 0 0 auto;
      padding: 12px 16px;
      position: relative;
      z-index: 1;

      &:before {
        -webkit-border-radius: 50%;
        border-radius: 50%;
        content: '';
        display: block;
        height: 30px;
        left: 28px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        z-index: -1;
      }

      >div {
        height: 24px;
        position: relative;
        width: 24px;

        img {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

.mat-calendar-body-selected {
  background-color: admefy-color('green') !important;
  color: admefy-color('light') !important;
}

.w-inherit {
  width: inherit !important;
}

.inset-0 {
  inset: 0 !important;
}

.mdc-switch__track::after,
.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
.text-bg-info {
  background-color: admefy-color('green')!important;
}

.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background-color: admefy-color('carbon')!important;
}

.overlay-login-box {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw !important;
  border-radius: 0;

  .opacity-overlay {
    background: rgba(0, 0, 0, 0.7);
    background-blend-mode: color;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -9;
  }

  #video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    z-index: -10;
    visibility: visible;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input>input {
    background: transparent !important;
  }

  .overlay-login-box #form-signup-job .ng-select .ng-placeholder {
    color: #A7A3AD !important;
    /* Body -  Regular */
    font-family: SF Pro Text, SF UI text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */

  }

  .overlay-login-box {
    background: transparent !important;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: transparent !important;

    .mat-mdc-tab-body-active,
    .mat-mdc-tab-body-content {

      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
  }
}

.mdc-linear-progress__bar-inner {
  border-color: admefy-color('green')!important;
}

*:disabled {
  opacity: 0.3;
}
